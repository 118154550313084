$(document).foundation();



$(document).ready(function(){
	$('.slickslider_home').slick({
	// setting-name: setting-value
	infinite: false,
	arrows: true
	});

	//customize
	// $('img.light-zoom').lightzoom({
	//     zoomPower   : 3,    //Default
	//     glassSize   : 220  //Default
	// });



$( function() {
    $( "#showmenu" ).on( "click", function() {
      $( ".hiddenmenu_top" ).toggleClass( "toggle_display", 1000 );
    });
  } );


 $('img.light-zoom').magnify();



	// $('#myNavdrawer').navdrawer(
	// 	)

	$('#myNavdrawer').navdrawer('toggle')


});
		